export default [


    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        
        resource: 'all',
        action: 'manage',
  
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Testudy - generate single and multiple choice tests from any text',
            active: true,
          },
        ],
      },
    },


    {
      path: '/addtext',
      name: 'addtext',
      component: () => import('@/views/AddText.vue'),
      meta: {
        
        resource: 'all',
        action: 'manage',
  
        pageTitle: 'Create Your Test',
        breadcrumb: [
          {
            text: 'Create own quiz',
            active: true,
          },
        ],
      },
    },    

    {
      path: '/quizzes',
      name: 'quizzes-page',
      component: () => import('@/views/pages/quizzes/Quizzes.vue'),
      meta: {
        pageTitle: 'Quizzes List',
        breadcrumb: [
          {
            text: 'All your tests here',
            active: true,
          },
        ],
      },
    },

    {
      path: '/quiz/:uid',
      name: 'quiz-page',
      component: () => import('@/views/pages/quizzes/Quiz.vue'),
      meta: {
        pageTitle: 'Quiz',
        breadcrumb: [
          {
            text: 'Path the quiz',
            active: true,
          },
        ],
      },
    },    

    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        action: 'read',
      },
    },
    {
      path: '/login',
      name: 'auth-login',
      component: () => import('@/views/pages/authentication/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    // {
    //   path: '/login',
    //   name: 'login',
    //   component: () => import('@/views/Login.vue'),
    //   meta: {
    //     layout: 'full',
    //   },
    // },

    // {
    //   path: '/pages/authentication/login-v1',
    //   name: 'auth-login-v1',
    //   component: () => import('@/views/pages/authentication/Login-v1.vue'),
    //   meta: {
    //     layout: 'full',
    //   },
    // },
    // {
    //   path: '/pages/authentication/login-v2',
    //   name: 'auth-login-v2',
    //   component: () => import('@/views/pages/authentication/Login-v2.vue'),
    //   meta: {
    //     layout: 'full',
    //   },
    // },
    {
      path: '/register',
      name: 'auth-register',
      component: () => import('@/views/pages/authentication/Register.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    // {
    //   path: '/pages/authentication/register-v1',
    //   name: 'auth-register-v1',
    //   component: () => import('@/views/pages/authentication/Register-v1.vue'),
    //   meta: {
    //     layout: 'full',
    //   },
    // },
    // {
    //   path: '/pages/authentication/register-v2',
    //   name: 'auth-register-v2',
    //   component: () => import('@/views/pages/authentication/Register-v2.vue'),
    //   meta: {
    //     layout: 'full',
    //   },
    // },
    {
      path: '/forgot-password',
      name: 'auth-forgot-password',
      component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    // {
    //   path: '/pages/authentication/forgot-password-v1',
    //   name: 'auth-forgot-password-v1',
    //   component: () => import('@/views/pages/authentication/ForgotPassword-v1.vue'),
    //   meta: {
    //     layout: 'full',
    //   },
    // },
    // {
    //   path: '/pages/authentication/forgot-password-v2',
    //   name: 'auth-forgot-password-v2',
    //   component: () => import('@/views/pages/authentication/ForgotPassword-v2.vue'),
    //   meta: {
    //     layout: 'full',
    //     resource: 'Auth',
    //   },
    // },
    // {
    //   path: '/pages/authentication/reset-password-v1',
    //   name: 'auth-reset-password-v1',
    //   component: () => import('@/views/pages/authentication/ResetPassword-v1.vue'),
    //   meta: {
    //     layout: 'full',
    //   },
    // },
    {
      path: '/resetpassword',
      name: 'auth-reset-password-v2',
      component: () => import('@/views/pages/authentication/ResetPassword-v2.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        action: 'read',      
      },
    },

    {
      path: '/pages/miscellaneous/not-authorized',
      name: 'misc-not-authorized',
      component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },

    // {
    //   path: '/pages/account-setting',
    //   name: 'pages-account-setting',
    //   component: () => import('@/views/pages/account-setting/AccountSetting.vue'),
    //   meta: {
    //     pageTitle: 'Account Settings',
    //     breadcrumb: [
    //       {
    //         text: 'Pages',
    //       },
    //       {
    //         text: 'Account Settings',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    // {
    //   path: '/pages/profile',
    //   name: 'pages-profile',
    //   component: () => import('@/views/pages/profile/Profile.vue'),
    //   meta: {
    //     pageTitle: 'Profile',
    //     breadcrumb: [
    //       {
    //         text: 'Pages',
    //       },
    //       {
    //         text: 'Profile',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
  
    {
      path: '/pricing',
      name: 'pages-pricing',
      component: () => import('@/views/pages/pricing/Pricing.vue'),
      meta: {
        pageTitle: 'Premium',
        breadcrumb: [
          {
            text: 'Pricing plans',
            active: true,
          },
        ],
      },      
    },

    {
      path: '/profile',
      name: 'pages-profile',
      component: () => import('@/views/pages/profile/SimpleProfile.vue'),
      meta: {
        pageTitle: 'Profile',
        breadcrumb: [
          {
            text: 'Your account and billing information',
            active: true,
          },
        ],
      },
    },


  
  




  ]
  