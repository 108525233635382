import jwtDefaultConfig from './jwtDefaultConfig'

import router from '@/router/index'



export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.isAlreadyFetchingAccessToken = false
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }
    this.isLoginFromLoginpage = false

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        // if (accessToken) {
        if ((accessToken) && (typeof accessToken !== "undefined")) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
      // error => { // do not working
      //   try {
      //     let errrespresponse = {
      //       data : {'email':'Sorry, the service is not available. Try later. ' + request.statusText}
      //     }
      //     return Promise.reject(errrespresponse);         
      //   } catch (e) {
      //     console.log(e);
      //   }
      // }
    )


    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // if (status === 401) {
        
        // is authorise from login page with wrong password
        if (this.isLoginFromLoginpage && response && response.status === 401)  {
          //response.data.email = response.statusText;
          response.data.email = response.data.detail;
          // delete response.data.detail;
          return Promise.reject(response); 
        } else
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              // Update accessToken in localStorage
              this.setToken(r.data.access)
              
              this.setRefreshToken(r.data.refresh)
              

              this.onAccessTokenFetched(r.data.access)
            })
            .catch(er => { //new
              // console.log('errrrrrrrrrrrrrrrrrrrrrrrrrrrrr 1');
              // response.data.email = er.data.detail;
              // return Promise.reject(er);               
              // console.log(er);
              // return Promise.reject(errrespresponse);       
              // er.data.email = er.data.detail;
              // return Promise.reject(er);               
              // return Promise.reject({'email':'Login or password is cincorrect'});
              // return er;
              
              // logout and redirect to authorisation page
              localStorage.removeItem(this.jwtConfig.storageTokenKeyName);
              localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName);
              localStorage.removeItem('userData');
              router.push({ name: 'auth-login' });
            })
          } else {
            response.data.email = response.data.detail;
            return Promise.reject(response); 
          }

          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              
              //new
              accessToken = this.getToken()
              
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        else if (response && response.status === 429) { // too many requests
          // console.log('response.status')
          // console.log(response.status)
          // console.log('response')
          // console.log(response)
  
          response.data.email = response.statusText;
          // delete response.data.detail;
          return Promise.reject(response);  
        } else
        {
          // console.log('response.status')
          // console.log(response.status)
          // console.log('response')
          
          
          if (response.data.email) { // if error? API send {"email": ["user with this email already exists."]}
            return Promise.reject(response); 
          } 
          else if (response.data.detail) { 
            response.data.email = response.data.detail;
            return Promise.reject(response); 
          }          
          else {
            let errrespresponse = {
              data : {'email':'Sorry, the service is not available. Try later. ' + response.statusText}
            }
            return Promise.reject(errrespresponse); 
          }
        }
        

        // return Promise.reject(error)
      },
    )



    // axios.interceptors.request.use(function (config) {
    //   // Do something before request is sent
    //   return config;
    // }, function (error) {
    //   // Do something with request error
    //   return Promise.reject(error);
    // });
  
  
  
  
   /* 
  // Add a response interceptor
  this.axiosIns.interceptors.response.use(function (response) {
      // Do something with response data
      //console.log(response);
      return response;
    },
    function (error) {
      // Do something with response error
      // console.log('error');
      // console.log(error);
      
      const { config, response} = error
      // const { config, response: { status, statusText}, data: {code, detail} } = error
      const originalRequest = config
      console.log(response);
      const status = response.status
      // if (response && response.status === 401) {
      //   return Promise.reject({'email':'Login or password is cincorrect',});  
      // }


      // console.log(response.data);

      // if (response && response.status === 401) { 
      //   response.data.email = response.data.detail;
      //   delete response.data.detail;
      //   return Promise.reject(response);  
      // } else
      console.log('status');
      console.log(status);
      if (status === 401) {
        if (response && response.status === 401) {
          console.log('401')
          // console.log(this.getRefreshToken())

          // this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
          //   //refreshToken: this.getRefreshToken(),
          //   refresh: this.getRefreshToken(),
          // })

          response.data.email = response.data.detail;
          delete response.data.detail;
          return Promise.reject(response); 
          // return {data: {'email':'asdasdasdasdasd',}}



            // if (!this.isAlreadyFetchingAccessToken) {

              // response.data.email = response.data.detail;
              // delete response.data.detail;
              // return Promise.reject(response);  


              // this.isAlreadyFetchingAccessToken = true
              


            // this.refreshToken()

            // const promise = new Promise(resolve => {
            //     consloe.log(1)
            //     this.refreshToken().then(r => {
            //       consloe.log(2)
            //       this.setToken(r.data.access)
            //       this.setRefreshToken(r.data.refresh)
            //       // this.onAccessTokenFetched(r.data.access)
            //     })
            //     console.log(3) 
                        
              
            //   },
            //   reject => {
            //     consloe.log(4)
            //   }
            //   );

            // promise.then( 
            //   console.log('I am here'),
            //   // result => console.log('I am here'),
            //   // error => console.log('I am here 2'),
            // );


            // }
            // const retryOriginalRequest = new Promise(resolve => {
            //   console.log('I am here');
            //   this.addSubscriber(accessToken => {
            //     // Make sure to assign accessToken according to your response.
            //     // Check: https://pixinvent.ticksy.com/ticket/2413870
            //     // Change Authorization header
            //     originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
            //     resolve(this.axiosIns(originalRequest))
            //   }              
            //   )
            // })

            // // retryOriginalRequest.data.email = response.data.detail;
            // return retryOriginalRequest
        }

        // let errrespresponse = {
        //   data : {'email':'Sorry, the service is not available. Try later'}
        // }
        // return Promise.reject(errrespresponse);    

        // response.data.email = response.data.detail;
        // delete response.data.detail;
        // return Promise.reject(response);  
                      
        
      }   else 
      
      
      if (response && response.status === 429) { // too many requests
        console.log('response.status')
        console.log(response.status)
        console.log('response')
        console.log(response)

        response.data.email = response.statusText;
        // delete response.data.detail;
        return Promise.reject(response);  
      } else
      {
        // console.log('response.status')
        // console.log(response.status)
        // console.log('response')
        // console.log(response)

        let errrespresponse = {
          data : {'email':'Sorry, the service is not available. Try later'}
        }
        return Promise.reject(errrespresponse); 
      }
    });  */

/*
    
    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => {
        console.log(response);
        return response;
      },
      error => {
        
        return Promise.reject({'email':'asdasdasdasdasd',})
      }

      // error => {
      //   const { config, response: { status } } = error
      //   // const { config, response } = error
      //   // const { config, response } = detail
      //   const originalRequest = config

      //   // console.log('jwtService.js:');
      //   // console.log(response);
  

      //   // if (status === 401) {
      //   if (response && response.status === 401) {
      //     console.log(response.status);
      //     if (!this.isAlreadyFetchingAccessToken) {
      //       this.isAlreadyFetchingAccessToken = true
      //       this.refreshToken().then(r => {
      //         this.isAlreadyFetchingAccessToken = false

      //         // Update accessToken in localStorage
      //         this.setToken(r.data.accessToken)
      //         this.setRefreshToken(r.data.refreshToken)

      //         this.onAccessTokenFetched(r.data.accessToken)
      //       })
      //     }
      //     const retryOriginalRequest = new Promise(resolve => {
      //       this.addSubscriber(accessToken => {
      //         // Make sure to assign accessToken according to your response.
      //         // Check: https://pixinvent.ticksy.com/ticket/2413870
      //         // Change Authorization header
      //         originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
      //         resolve(this.axiosIns(originalRequest))
      //       })
      //     })
      //     return retryOriginalRequest
      //   }
      //   return Promise.reject(error)
      // },
    )*/
    
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    this.isLoginFromLoginpage = true
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      //refreshToken: this.getRefreshToken(),
      refresh: this.getRefreshToken(),
    })
  }

  addText(text_type, ...args) {
    if (text_type == '0') {
      return this.axiosIns.post(this.jwtConfig.addtextEndpoint, ...args)
    } else 
    {
      return this.axiosIns.post(this.jwtConfig.textaddbythemeEndpoint, ...args)
    }
    
  }  

  getUserTexts(...args) {
    return this.axiosIns.get(this.jwtConfig.getUserTextEndpoint, ...args)
  }  

  getQuiz(uid, ...args) {
    return this.axiosIns.get(this.jwtConfig.getQuizEndpoint + uid + '/', ...args)
  }   

  getUserProfile(...args) {
    return this.axiosIns.get(this.jwtConfig.getUserProfileEndpoint, ...args)
  }  

  getTopTransactions(...args) {
    return this.axiosIns.get(this.jwtConfig.getTopTransactionsEndpoint, ...args)
  }  
  
  getAllTransactions(...args) {
    return this.axiosIns.get(this.jwtConfig.getAllTransactionsEndpoint, ...args)
  }    

  getSubscriptions(...args) {
    return this.axiosIns.get(this.jwtConfig.getSubscriptionsEndpoint, ...args)
  }    

  deleteQuiz(qid, ...args) {
    return this.axiosIns.delete(this.jwtConfig.deleteQuizEndPoint + qid + '/', ...args)
  }  
  
  deactivateQuestion(...args) {
    return this.axiosIns.patch(this.jwtConfig.upQIsActiveEndPoint, ...args)
  }     

  getAuthorofQuiz(uid, ...args) {
    return this.axiosIns.get(this.jwtConfig.authorofquizEndPoint + uid + '/', ...args)
  }   

  getText(textid, ...args) {
    return this.axiosIns.get(this.jwtConfig.gettextEndPoint + textid + '/', ...args)
  }   

  activateAccount(uid, token, ...args) {
    return this.axiosIns.get(this.jwtConfig.activateAccountEndPoint + uid + '/' + token + '/', ...args)
  }  

  resetPassword(...args) {
    return this.axiosIns.post(this.jwtConfig.resetPasswordEndPoint, ...args)
  }
  
  confirmResetedPassword(...args) {
    return this.axiosIns.post(this.jwtConfig.confirmResetedPasswordEndPoint, ...args)
  }

  googleAuth(...args) {
    return this.axiosIns.post(this.jwtConfig.googleAuthEndpoint, ...args)
  }


}
