export default {
  // Endpoints
  // loginEndpoint: '/jwt/login',
  // registerEndpoint: '/jwt/register',
  // refreshEndpoint: '/jwt/refresh-token',
  // logoutEndpoint: '/jwt/logout',




  loginEndpoint: 'https://api.testudy.io/apifront/token/',
  registerEndpoint: 'https://api.testudy.io/apifront/registeruser/',
  refreshEndpoint: 'https://api.testudy.io/apifront/token/refresh/',
  logoutEndpoint: '/jwt/logout',
  addtextEndpoint: 'https://api.testudy.io/apifront/textadd/',
  textaddbythemeEndpoint: 'https://api.testudy.io/apifront/textaddbytheme/',
  getUserTextEndpoint: 'https://api.testudy.io/apifront/usertextview/',
  getQuizEndpoint: 'https://api.testudy.io/apifront/quiz/',
  getUserProfileEndpoint: 'https://api.testudy.io/apifront/userprofile/',
  getTopTransactionsEndpoint: 'https://api.testudy.io/apifront/toptransactions/',
  getAllTransactionsEndpoint: 'https://api.testudy.io/apifront/alltransactions/',
  deleteQuizEndPoint: 'https://api.testudy.io/apifront/deletetext/',
  upQIsActiveEndPoint: 'https://api.testudy.io/apifront/up_q_isactive/',
  authorofquizEndPoint: 'https://api.testudy.io/apifront/authorofquiz/',
  gettextEndPoint: 'https://api.testudy.io/apifront/gettext/',
  activateAccountEndPoint: 'https://api.testudy.io/apifront/activateaccount/',
  resetPasswordEndPoint: 'https://api.testudy.io/apifront/passwordreset/',
  confirmResetedPasswordEndPoint: 'https://api.testudy.io/apifront/passwordreset/confirm/',
  getSubscriptionsEndpoint: 'https://api.testudy.io/apifront/activesubscriptions/',
  googleAuthEndpoint: 'https://api.testudy.io/auth/google/',

  // loginEndpoint: 'http://my.testudy.io:8080/apifront/token/',
  // registerEndpoint: 'http://my.testudy.io:8080/apifront/registeruser/',
  // refreshEndpoint: 'http://my.testudy.io:8080/apifront/token/refresh/',
  // logoutEndpoint: '/jwt/logout',
  // addtextEndpoint: 'http://my.testudy.io:8080/apifront/textadd/',
  //textaddbythemeEndpoint: 'http://my.testudy.io:8080/apifront/textaddbytheme/',
  // getUserTextEndpoint: 'http://my.testudy.io:8080/apifront/usertextview/',
  // getQuizEndpoint: 'http://my.testudy.io:8080/apifront/quiz/',
  // getUserProfileEndpoint: 'http://my.testudy.io:8080/apifront/userprofile/',
  // getTopTransactionsEndpoint: 'http://my.testudy.io:8080/apifront/toptransactions/',
  // getAllTransactionsEndpoint: 'http://my.testudy.io:8080/apifront/alltransactions/',
  // deleteQuizEndPoint: 'http://my.testudy.io:8080/apifront/deletetext/',
  // upQIsActiveEndPoint: 'http://my.testudy.io:8080/apifront/up_q_isactive/',
  // authorofquizEndPoint: 'http://my.testudy.io:8080/apifront/authorofquiz/',
  // gettextEndPoint: 'http://my.testudy.io:8080/apifront/gettext/',
  // activateAccountEndPoint: 'http://my.testudy.io:8080/apifront/activateaccount/',
  // resetPasswordEndPoint: 'http://my.testudy.io:8080/apifront/passwordreset/',
  // confirmResetedPasswordEndPoint: 'http://my.testudy.io:8080/apifront/passwordreset/confirm/',
  //getSubscriptionsEndpoint: 'http://my.testudy.io:8080/apifront/activesubscriptions/',
  //googleAuthEndpoint: 'http://my.testudy.io:8080/auth/google/',



  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
